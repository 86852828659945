<template>
  <div v-loading="loading">
    <el-row class="statistics_top" style="margin-top: 8px">
      <el-card class="box-card" style="width: 33%">
        <div slot="header" class="clearfix">
          <span class="title_fw">项目类型分析</span>
          <span class="title_right"
            ><SearchUserDate
              :title="titleYear"
              :type="'ProjectType'"
              :yearIsShow="true"
              :list="dictData.yearList"
              v-model="sectionInfo.projectTypeYear"
              @change="onProjectTypeChange"
          /></span>
          <el-button class="title_right" type="primary" size="small" @click="onProjectTypeImg"
            >数据来源说明</el-button
          >
        </div>
        <!-- <ProjectType :projectTypeList="projectTypeList" /> -->
      </el-card>
      <el-card class="box-card" style="width: 33%">
        <div slot="header" class="clearfix">
          <span class="title_fw">项目状态分析</span>
          <span class="title_right"
            ><SearchUserDate
              :title="titleYear"
              :type="'ProjectSource'"
              :yearIsShow="true"
              :list="dictData.yearList"
              v-model="sectionInfo.projectStatusYear"
              @change="onProjectSourceChange"
          /></span>
          <el-button class="title_right" type="primary" size="small" @click="onProjectTypeImg"
            >数据来源说明</el-button
          >
        </div>
        <!-- <ProjectStatus :projectStatusList="projectStatusList" /> -->
      </el-card>
      <el-card class="box-card" style="width: 33%">
        <div slot="header" class="clearfix">
          <span class="title_fw">资金风险监控</span>
          <span class="title_right"
            ><SearchUserDate
              :title="titleYear"
              :type="'CapitalRisk'"
              :yearIsShow="true"
              :list="dictData.yearList"
              v-model="sectionInfo.capitalRiskYear"
              @change="onCapitalRiskChange"
          /></span>
        </div>
        <!-- <CapitalRisk :capitalRiskList="capitalRiskList" />  -->
      </el-card></el-row
    >
    <el-dialog width="75%" title="数据来源说明" :visible.sync="isDialog">
      <div style="padding: 5px">
        <img
          :src="`https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/${imgUrl}.png?${new Date().getTime()}`"
          alt="数据来源说明"
          width="100%"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    // ProjectType: () => import('../projectType.vue'),
    // ProjectStatus: () => import('../projectStatus.vue'),
    // CapitalRisk: () => import('../capitalRisk.vue'),
    SearchUserDate: () => import('../searchUserDate.vue'),
  },
  data() {
    return {
      loading: false, // 加载
      dictData: {
        yearList: [],
      },
      addBusinessList: [], // 季度新增商务数据表
      projectTypeList: [], // 项目类型分析
      projectStatusList: [], // 项目状态分析
      capitalRiskList: [], // 资金风险监控
      sectionInfo: {
        // 条件筛选
        // 年份
        addBusinessYear: null,
        projectTypeYear: null,
        projectStatusYear: null,
        capitalRiskYear: null,
      },
      titleYear: '统计时间：',
      isDialog: false,
      imgUrl: '',
    }
  },
  provide() {
    return {}
  },
  computed: {},
  created() {
    this.getType()
  },
  filters: {
    applyAmount(n) {
      if (n) {
        if (n === null || n === undefined) return ''
        // 转换为数字并保留两位小数
        let number = parseFloat(n).toFixed(2)
        // 增加千分位
        let parts = number.split('.')
        let integerPart = parts[0]
        let decimalPart = parts[1]
        // 使用正则表达式添加千分位
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return `${integerPart}.${decimalPart}`
      } else {
        return n
      }
    },
  },
  watch: {},
  mounted() {},
  methods: {
    async init(val) {
      const businessLines = await this.$api.dict.listSysDictData('BUSINESS_LINES', true)
      const projectStatus = await this.$api.dict.listSysDictData('PROJECT_NEW_STATUS', true)
      this.loading = true
      Promise.all([
        this.$api.statistics.queryNewBusinessDataQuarterly(`date=${val}`),
        this.$api.statistics.queryProjectCategorySummary(`date=${val}`),
        this.$api.statistics.queryProjectStatusSummary(`date=${val}`),
        this.$api.statistics.queryFundRiskMonitoring(`date=${val}`),
      ])
        .then(res => {
          // 由前端根据选择年份去过滤数据
          const arr = res[0].data.quarterlyBusinessDataVoList || []
          this.addBusinessList = arr.filter(v => v.year == val)

          this.projectTypeList = res[1].data?.businessCategoryResponseList.map(item => {
            const businessLinesItem = businessLines.data.find(v => v.dictVal === item.categoryName)
            return {
              value: item.categoryAmount,
              percentage: Number((item.percentage * 100).toFixed(2)),
              categoryCount: item.categoryCount,
              name: businessLinesItem ? businessLinesItem.dictName : item.categoryName, // 如果找不到则使用原名称
            }
          })

          this.projectStatusList = res[2].data?.projectStatusStatisticsVoList.map(item => {
            const projectStatusItem = projectStatus.data.find(v => v.dictVal === item.projectStatus)
            return {
              value: item.projectAmount,
              projectPercentage: Number((item.projectPercentage * 100).toFixed(2)),
              projectStatusCount: item.projectStatusCount,
              name: projectStatusItem ? projectStatusItem.dictName : item.projectStatus, // 如果找不到则使用原名称
            }
          })

          this.capitalRiskList = res[3].data?.fundingRiskTypeStatisticsVoList.map(item => {
            return {
              value: item.cost,
              percentage: Number((item.percentage * 100).toFixed(2)),
              typeCount: item.typeCount,
              name: item.fundingRiskName,
            }
          })

          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    onAddBusinessImg() {
      this.imgUrl = '季度新增商务数据表'
      this.isDialog = true
    },
    onProjectTypeImg() {
      this.imgUrl = '数据分析'
      this.isDialog = true
    },
    onAddBusinessChange(arr) {
      this.addBusinessList = arr
    },
    onProjectTypeChange(arr) {
      this.projectTypeList = arr
    },
    onProjectSourceChange(arr) {
      this.projectStatusList = arr
    },
    onCapitalRiskChange(arr) {
      this.capitalRiskList = arr
    },

    getType() {
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(res => {
          this.dictData.yearList = res.data
          const { dictVal = '' } = res.data[0] || null
          this.sectionInfo.addBusinessYear = dictVal
          this.sectionInfo.projectTypeYear = dictVal
          this.sectionInfo.projectStatusYear = dictVal
          this.sectionInfo.capitalRiskYear = dictVal
          this.init(dictVal)
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.title_fw {
  font-size: 100%;
  font-weight: 700;
  color: #000;
}
.title_right {
  float: right;
  margin-left: 8px;
  font-size: 12px;
}
.statistics_top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>
